<template>
    <div>
      <b-container v-if="loading">
        <div class="mt-3"></div>
        <div class="text-center">
          <b-spinner label="Loading..." variant="warning"></b-spinner>
        </div>
        <div class="mt-3"></div>
      </b-container>
      
      <b-container v-if="!loading">
        <!-- source: https://bootstrap-vue.org/docs/components/carousel -->
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <span v-for="im in homearticle.carouselImageCollection.items" :key="im.title">
            <b-carousel-slide
              :caption= im.title
              :text=im.description
              :img-src=im.url
            ></b-carousel-slide>
          </span>
        </b-carousel>
        <div class="mt-4"></div>
        <h5 class="text-center text-primary">{{ homearticle.title }}</h5>
        <RichTextRenderer :document="homearticle.body.json" />
      </b-container>
    </div>
</template>

<script>
  import HomeArticleService from '@/services/HomeArticleService.js';
  // reference: https://github.com/paramander/contentful-rich-text-vue-renderer/issues/9
  // reference: https://github.com/paramander/contentful-rich-text-vue-renderer
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';

  export default {
    name: 'home',
    title: "TPA Gembira - Home",
    data() {
      return {
        homearticle: {},
        loading: false,
        slide: 0,
        sliding: null
      }
    },
    components: {
      RichTextRenderer
    },
    created(){
      this.getHomeArticleData();
    },
    methods: {
      onSlideStart(slide) {
        this.slide = slide;
        this.sliding = true;
      },
      onSlideEnd(slide) {
        this.slide = slide;
        this.sliding = false
      },
      async getHomeArticleData(){
        this.loading = true;
        HomeArticleService.getHomeArticle()
          .then(
            (homearticle => {
              this.$set(this, "homearticle", homearticle);
              this.loading = false;
            }).bind(this)
          );
      }
    }
  } 
</script>

<style lang="sass" scoped>
</style>