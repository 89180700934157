import { getInstance } from "./index";
import mapPageAuth from "./mapPageAuth";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // Only if the user is authenticated and have permission, continue with the route
    if (authService.isAuthenticated) {
      if (mapPageAuth.has(to.name)){
        let intersectionRole = mapPageAuth.get(to.name).filter(value => authService.user[`https://tpa.musihoven.nl/roles`].includes(value));
        if (intersectionRole.length == 0){
          next('/');
        }
      }
      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check the auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before checking isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};