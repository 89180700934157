<template>
  <div class="p-3 mb-2 bg-dark text-white">&#169; 2021 TPA Gembira</div>
</template>

<script>
export default {
  name: "Footer",
  methods: {},
};
</script>

<style>
#Footer {
  padding-top: 4px;
  padding-bottom: 4px;
}

</style>