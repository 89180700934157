export default {
  methods: {
    generateNameLabel: function(given_name, family_name, nickname, email) {
      if (
        typeof given_name !== "undefined" &&
        typeof family_name !== "undefined" &&
        (given_name !== "" || family_name !== "") &&
        (given_name !== null || family_name !== null)
      ) {
        let tempName = given_name !== null ? given_name : "";
        if (tempName.length > 0) {
          tempName += " ";
        }
        tempName += family_name !== null ? family_name : "";
        return tempName;
      } else {
        if (
          typeof nickname !== "undefined" &&
          nickname !== null &&
          nickname.trim() !== ""
        ) {
          return nickname;
        } else {
          return email;
        }
      }
    },
    generateNameLabels: function(userList) {
      return userList
        .map((student) => {
          student["label"] = this.generateNameLabel(
            student.given_name,
            student.family_name,
            student.nickname,
            student.email
          );
          return student;
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    },
  },
};
