<template>
  <div id="Nav">

    <!-- Check that the SDK client is not currently loading before accessing is methods -->
    <span v-if="!$auth.loading">
    </span>

    <b-navbar toggleable="lg" type="light" variant="light">
      <b-container>
        <b-navbar-brand><router-link to="/">TPA Gembira</router-link></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item><router-link to="/">Home</router-link></b-nav-item>
            <b-nav-item><router-link to="/gallery">Gallery</router-link></b-nav-item>
            <b-nav-item><router-link to="/contact">Contact Us</router-link></b-nav-item>
            <b-nav-item-dropdown v-if="$auth.isAuthenticated && !$auth.loading && $auth.user['https://tpa.musihoven.nl/roles'].includes('Pengurus')" text="Organizer" toggle-class="nav-link-custom text-primary">
              <b-dropdown-item><router-link to="/mapteachingsession">Map teaching session</router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/sessionmanagement">Session management</router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/usermanagement">User management</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown v-if="$auth.isAuthenticated && !$auth.loading && $auth.user['https://tpa.musihoven.nl/roles'].includes('Pengajar')" text="Teacher" toggle-class="nav-link-custom text-primary">
              <b-dropdown-item><router-link to="/attendance">Teacher attendance</router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/mapteachingsessionview">View teaching session</router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/studentevaluation">Student evaluation</router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/studentevaluationview">View student evaluation</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown v-if="$auth.isAuthenticated && !$auth.loading && $auth.user['https://tpa.musihoven.nl/roles'].includes('Orangtua')" text="Parents" toggle-class="nav-link-custom text-primary">
              <b-dropdown-item><router-link to="/attendancestudent">Student attendance</router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/mapteachingsessionview">View teaching session</router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/studentevaluationparentsview">View children's evaluation</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav v-if="$auth.isAuthenticated && !$auth.loading" class="ml-auto">
            <b-nav-item-dropdown right>
              <template slot="button-content">{{ $auth.user.name }}</template>
              <b-dropdown-item><router-link to="/profile">Update profile</router-link></b-dropdown-item>
              <b-dropdown-item class="text-primary" @click="logout">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav v-else class="ml-auto">
            <b-button @click="login" variant="primary">Login/Sign Up</b-button>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Nav",
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    }
  },
};
</script>

<style>
#Nav {
  padding-top: 4px;
  padding-bottom: 4px;
}

#nav-item {
  margin-right: 7px;
}
</style>