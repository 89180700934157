import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home, 
    meta: {
      title: 'TPA Gembira - Home'
    }
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import('../views/Gallery.vue'),
    meta: {
      title: 'TPA Gembira - Gallery'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'TPA Gembira - Profile'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      title: 'TPA Gembira - Contact'
    }
  },
  {
    path: '/studentevaluation',
    name: 'studentevaluation',
    component: () => import('../views/StudentEvaluation.vue'),
    meta: {
      title: 'TPA Gembira - Student evaluation'
    },
    beforeEnter: authGuard
  },
  {
    path: '/studentevaluationview',
    name: 'studentevaluationview',
    component: () => import('../views/StudentEvaluationView.vue'),
    meta: {
      title: 'TPA Gembira - View student evaluation'
    },
    beforeEnter: authGuard
  },
  {
    path: '/studentevaluationparentsview',
    name: 'studentevaluationparentsview',
    component: () => import('../views/StudentEvaluationParentsView.vue'),
    meta: {
      title: 'TPA Gembira - View children evaluation'
    },
    beforeEnter: authGuard
  },
  {
    path: '/usermanagement',
    name: 'usermanagement',
    component: () => import('../views/UserManagement.vue'),
    meta: {
      title: 'TPA Gembira - User Management'
    },
    beforeEnter: authGuard
  },
  {
    path: '/attendance',
    name: 'attendance',
    component: () => import('../views/Attendance.vue'),
    meta: {
      title: 'TPA Gembira - Attendance'
    },
    beforeEnter: authGuard
  },
  {
    path: '/sessionmanagement',
    name: 'sessionmanagement',
    component: () => import('../views/SessionManagement.vue'),
    meta: {
      title: 'TPA Gembira - Session Management'
    },
    beforeEnter: authGuard
  },
  {
    path: '/attendancestudent',
    name: 'attendancestudent',
    component: () => import('../views/AttendanceStudent.vue'),
    meta: {
      title: 'TPA Gembira - Student Attendance'
    },
    beforeEnter: authGuard
  },
  {
    path: '/mapteachingsession',
    name: 'mapteachingsession',
    component: () => import('../views/MapTeachingSession.vue'),
    meta: {
      title: 'TPA Gembira - Map Teaching Session'
    },
    beforeEnter: authGuard
  },
  {
    path: '/mapteachingsessionview',
    name: 'mapteachingsessionview',
    component: () => import('../views/MapTeachingSessionView.vue'),
    meta: {
      title: 'TPA Gembira - View Teaching Session'
    },
    beforeEnter: authGuard
  },
  {
    path: '/blogpost/:id',
    name: 'blogPostSingle',
    component: () => import('../views/BlogPostSingle.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router