<template>
  <div id="app">
    <Nav />
    <b-alert 
      show 
      dismissible 
      variant="success" 
      v-if="$auth.isAuthenticated && typeof($auth.user['https://tpa.musihoven.nl/roles']) !== 'undefined' && $auth.user['https://tpa.musihoven.nl/roles'].length == 0">
      Your account has been registered and now pending for admin's approval. Please wait until further confirmation.
    </b-alert>
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Nav from './components/partials/Nav.vue'
import Footer from './components/partials/Footer.vue'
export default {
  name: 'App',
  components: {
    Nav, 
    Footer
  }
}
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

@font-face {
  font-family: "Avenir";
  font-weight: 400;
  font-style: normal;
  src: url("./assets/avenir-400.woff2") format("woff2");
  font-display: swap;
}

</style>
