import Vue from 'vue'
import App from './App.vue'
import router from './router'
import titleMixin from './mixins/titleMixin'
import userLabelMixin from './mixins/userLabelMixin'
import BootstrapVue from 'bootstrap-vue'

// Import Auth0 configuration and plugin
import { domain, clientId, audience } from "../auth_config.json";
import { Auth0Plugin } from "./auth";

// import stylesheet
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
// source: https://blog.logrocket.com/getting-started-with-bootstrapvue-2d8bf907ef11/
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Use mixin
Vue.mixin(titleMixin);
Vue.mixin(userLabelMixin);

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(BootstrapVue);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
